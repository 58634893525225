/**
 * sendAudienceData for JOIN Stories
 * @param {String} a language for the stories
 */
function sendAudienceData(a) {
    window.jDataLayer = window.jDataLayer || [];
    window.jDataLayer.push({ type: 'audience', data: a });
}
window.onload = () => {
    var lang = $('#join-stories').attr('language');
    sendAudienceData({ 'website-language': lang });
};

var targetNode = document.getElementById('join-widget-new-widget-homepage-web-fr-et-nl');
var config = { attributes: true, childList: true, subtree: true };
var callback = (mutations, observer) => {
    $('#join-widget-new-widget-homepage-web-fr-et-nl').removeClass('d-none');
    $('.join-widget-loading').addClass('d-none');
    observer.disconnect();
};
var observer = new MutationObserver(callback);
observer.observe(targetNode, config);
